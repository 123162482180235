.nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
.nav-item{
    display: flex;
    gap: 50px;
    text-transform: capitalize;
    font-weight: bold;
}
.nav-item > div {
    cursor: pointer;
}
.nav-item > div:hover {
    color: rgb(255, 85, 170);
}
.nav img{
    width: 150px;
}
.link{
    display: flex;
    gap: 20px;
}
.link i{
    font-size: 22px;
}