.rare{
    width: 100%;
    height: 70vh;
    margin-top: 100px;
    text-transform: capitalize;
    text-align: center;
    background: linear-gradient(rgb(166, 158, 255),rgb(46, 87, 165));
    border-radius: 50px;
    overflow: hidden;
    position: relative;
}
.rare-head{
    padding-top: 25px;
}
.rare h1{
    font-size: 50px;
    font-weight: lighter;
    color: #ffff;
}
.rare h2{
    font-size: 50px;
    color: #ffff;
}
.rare-img{
    display: flex;
    overflow: hidden;
    align-items: center;
}
.rare-img img{
    overflow: hidden;
    bottom: 0;
    width: 300px;
}
.rare-img .one{
    position: absolute;
    left: 15%;
    bottom: 0;
    transform: translate(-50%,0);
}
.rare-img .two{
    position: absolute;
    left: 30%;
    bottom: 0;
    transform: translate(-50%,0);
}
.rare-img .three{
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%,0);
    width: 400px;
    z-index: 1000;
}
.rare-img .four{
    position: absolute;
    left: 70%;
    bottom: 0;
    transform: translate(-50%,0);
    z-index: 100;
}
.rare-img .five{
    position: absolute;
    left: 85%;
    bottom: 0;
    transform: translate(-50%,0);
}