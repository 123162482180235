.about{
    height: 75vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;
}
.about-left{
    width: auto;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}
.ltop-img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    gap: 50px;
}
.top-big{
    width: 350px;
    height: 350px;
    background: linear-gradient(rgb(161, 79, 255),rgb(222, 169, 255));
    border-radius: 20px;
}
.top-big img{
    width: 100%;
    height: 100%;
}
.top-small-one img{
    width: 100%;
    height: 100%;
}
.top-small-two img{
    width: 100%;
    height: 100%;
}
.top-small-three img{
    width: 100%;
    height: 100%;
}
.btm-small-one img{
    width: 100%;
    height: 100%;
}
.btm-small-two img{
    width: 100%;
    height: 100%;
}
.btm-small-three img{
    width: 100%;
    height: 100%;
}
.top-small{
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.top-small-one{
    width: 150px;
    height: 150px;
    background:linear-gradient(#d1c020,#fff09d);
    border-radius: 20px;
}
.top-small-two{
    width: 150px;
    height: 150px;
    background:linear-gradient(#2076d1,#9dc8ff);
    border-radius: 20px;
}

.btm-img{
    display: flex;
    gap: 50px;
}
.btm-small-one{
    width: 150px;
    height: 150px;
    background:linear-gradient(#d12099,#ff9dfd);
    border-radius: 20px;
}
.btm-small-two{
    width: 150px;
    height: 150px;
    background:linear-gradient(#20d1b1,#9dffcb);
    border-radius: 20px;
}
.btm-small-three{
    width: 150px;
    height: 150px;
    background: linear-gradient(rgb(255, 158, 79),rgb(255, 212, 169));
    border-radius: 20px;
}

/*************************************/
.about-right{
    width: 50%;
    height: 75%;
}
.head-four{
    margin-top: 100px;
}
.head-four button{
    font-size: 18px;
    padding: 10px 30px 10px 30px;
    border-radius: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: linear-gradient(rgb(255, 79, 108),rgb(255, 169, 169));
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}
.head-two .blue{ 
    background:linear-gradient(#1AA37A,#9DFFB3);
    padding:5px 50px 5px 50px;
    border-radius: 50px;
    margin-right: 25px;
}
.head-one{
    font-size: 18px;
    text-transform: capitalize;
    font-weight: bold;
}
.head-two{
    font-size: 40px;
    font-weight: lighter;
}
.head-two h1{
    font-size: 50px;
    text-transform: capitalize;
}
.head-three{
    margin-top: 25px;
    font-size: 18px;
    color: gray;
    text-transform: capitalize;
    text-align: justify;
}
/* .big-img{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;

}
.big{
    height: 375px;
    width: 300px;
    background: linear-gradient(rgb(161, 79, 255),rgb(222, 169, 255));
    position: relative;
    border-radius: 20px;
    overflow-x: hidden;
}
.big img{
   bottom: 0; 
   position: absolute;
   height: 400px; 
   left: -50px;
}
.small{
    height: 375px;
    width: auto;
    position: relative;
}
.ssmall{
    height: 175px;
    width: 175px;
    background:linear-gradient(#d1c020,#fff09d);
    position: relative;
    border-radius: 20px;
    overflow-x: hidden;
}
.small .images{
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%,-50%);
    row-gap: 25px;
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.ssmall img{
    position: absolute;
    width: 175px;
    left: 50%;
    transform: translate(-50%,0%);
    bottom: 0;
}
.sone{
    background: linear-gradient(rgb(255, 232, 79),rgb(255, 252, 169));
}
.stwo{
    background:linear-gradient(#2076d1,#9dc8ff);
}
.small-down{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.small-down img{
    width: 175px;
    border: 1px solid red;
} */