*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Raleway', sans-serif; */
}
body{
  background: rgb(240, 240, 240);
}
