.team{
    width: 100%;
    height: 75vh;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.team-one{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
.team-two{
    font-size: 40px;
    font-weight: lighter;
    text-transform: capitalize;
    text-align: center;
}
.team-card{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-top: 50px;
    grid-gap: 25px;
}
.t-card{
    width: 250px;
    height: 350px;
    border-radius: 20px;
}
.t-card .t-card-top{
    width: 100%;
    height:50%;
    position: relative;
    background: linear-gradient(rgb(255, 79, 108),rgb(255, 169, 169));
    border-radius: 20px;
}
.t-card-top-one{
    width: 100%;
    height:50%;
    position: relative;
    background: linear-gradient(rgb(255, 229, 79),rgb(255, 238, 169));
    border-radius: 20px;
}
.t-card-top-two{
    width: 100%;
    height:50%;
    position: relative;
    background: linear-gradient(rgb(79, 255, 199),rgb(169, 255, 172));
    border-radius: 20px;
}
.t-card-top-three{
    width: 100%;
    height:50%;
    position: relative;
    background: linear-gradient(rgb(100, 79, 255),rgb(183, 169, 255));
    border-radius: 20px;
}
.t-card img{
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%,0);
    height: 250px;
}
.t-card-btm{
    text-align: center;
    margin-top: 10px;
    text-transform: capitalize;
}
.t-card-btm h5{
    font-size: 18px;
}